<template>
  <div class="vx-col  w-5/6 ml-auto mr-auto">
    <span>({{this.item.sku_code}}) {{this.item.item_name}}</span>

    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="data"
      :max-items="length"
      :total="total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2">{{this.start}} - {{this.end}} of {{this.total}}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item v-for="item in limits" :key="item" @click="handleChangelength(item)">
              <span>{{item}}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <vs-th sort-key="batch">Batch</vs-th>
        <vs-th sort-key="serial_number">Serial Number</vs-th>
        <vs-th sort-key="expired_date">Expired Date</vs-th>
        <vs-th sort-key="unit">Unit</vs-th>
        <vs-th sort-key="amount">Amount</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].batch">{{ data[indextr].batch }}</vs-td>
          <vs-td :data="data[indextr].serial_number">{{ data[indextr].serial_number }}</vs-td>
          <vs-td :data="data[indextr].expired_date">{{ data[indextr].expired_date }}</vs-td>
          <vs-td :data="data[indextr].unit">{{ data[indextr].unit }}</vs-td>
          <vs-td :data="data[indextr].amount">{{ data[indextr].amount }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top:5px"
      :total="totalPage"
      v-model="setPage"
      :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'"
      :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"
    />
  </div>
</template>
<script>
export default {
  props: ["item"],
  data() {
    return {
      data: [],
      length: 10,
      page: 1,
      search: "",
      order: "",
      sort: "asc",
      total: 0,
      totalPage: 0,
      limits: [10, 25, 50, 100, "All"],
      start: 1,
      end: this.length
    };
  },
  methods: {
    handleSearch(searching) {
      this.search = searching;
      this.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.order = key;
      this.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.length = val == "All" ? this.total : val;
      this.getData();
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/inventory-stock", {
          params: {
            "company-code": this.item.company_code,
            "warehouse-code": this.item.warehouse_code,
            "sku-code": this.item.sku_code,
            length: this.length,
            page: this.page,
            search: this.search,
            order: this.order,
            sort: this.sort
          }
        })
        .then(resp => {
          if (resp.status == "success") {
            this.total = resp.data.total_record;
            this.totalPage = resp.data.total_page;
            this.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            console.log(resp.data);
          }
        });
    },
    setStartEnd() {
      let valStart = this.length * this.page - this.length + 1;
      if (valStart > this.total) {
        valStart = 1;
      }
      let valEnd = this.length * this.page;
      if (valEnd > this.total) {
        valEnd = this.total;
      }
      this.start = valStart;
      this.end = valEnd;
    }
  },
  mounted() {
    this.getData();
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      }
    }
  },
  watch: {
    item() {
      this.length = 10;
      this.page = 1;
      this.searc = "";
      this.order = "";
      this.sort = "asc";
      this.getData();
    }
  }
};
</script>